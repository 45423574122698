@keyframes flyIn1 {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes flyIn2 {
  from {
    transform: translateX(150%);
  }
  to {
    transform: translateX(0%);
  }
}

.background-canvas{
  background-image: url("../public/background.jpg");
  background-repeat: round;
  width: 100vw;
  height: 100vh;
}

.triangle1, .triangle2, .portrait, .name, .slogan, .text, .skill {
  animation: flyIn1 1s ease-in-out forwards;
}

.triangle1 {
  position: relative;
  right: 0;
  height: 30vh;
  width: 40%;
  background-color: #3191c1;
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
}

.link-text{
  color: azure;
  font-size: 1.5vw;
  margin-left: 2vw;
}

.triangle2 {
  position: absolute;
  right: 0;
  top: 30vh;
  height: 60vh;
  width: 80%;
  background-color: #732c86;
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  animation-name: flyIn2;
}

.portrait{
  position: absolute;
  top: 20vh;
  height: 70vh;
  width: 40%;
  background-image: url("../public/portrait.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 100;
}

.name{
  position: absolute;
  font-size: 8vw; /* Changed to vw for responsiveness */
  font-weight: bolder;
  color: aliceblue;
  left: 50%;
  top: 7%;
}

.slogan{
  position: absolute;
  font-size: 4vw; /* Changed to vw for responsiveness */
  font-weight: bolder;
  color: aliceblue;
  left: 52%; /* Adjusted for responsiveness */
}

.slogan-top { top: 30%; }
.slogan-bottom { top: 35%; }

.text{
  position: absolute;
  font-size: 2vw; /* Changed to vw for responsiveness */
  font-family:Georgia, 'Times New Roman', Times, serif;
  color: aliceblue;
  left: 52%; /* Adjusted for responsiveness */
  top: 50%; /* Start from 40% and adjust below */
}

.text-university { top: 50%; }
.text:nth-child(8) { top: 54%; }
.text:nth-child(9) { top: 58%; }
.text-universities { top: 65%; }
.text:nth-child(11) { top: 69%; }
.text:nth-child(12) { top: 73%; }

.skill{
  position: absolute;
  font-size: 1.3vw; /* Changed to vw for responsiveness */
  font-family:Georgia, 'Times New Roman', Times, serif;
  color: aliceblue;
  left: 52%; /* Adjusted for responsiveness */
  right: 1%;
  top: 80%; /* Start from 40% and adjust below */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .name, .slogan, .text .skill {
    left: 40%; /* Adjust left positioning for smaller screens */
  }
  .triangle2 {
    width: 60%; /* Adjust width for smaller screens */
  }
  .portrait {
    display: none; /* Hide portrait for smaller screens */
  }
}

/* Social Media */
.social-media{
  width: 100vw;
  position: relative;
  /* background-image: url("../public/background.jpg");
  background-repeat: round;
  transform: scaleY(-1); */
}

.icon{
  top: 3vh;
  width: 20vw;
  height: 25vh;
  animation: continuous-flip 5s infinite linear;
}

.icon:hover{
  animation-play-state: paused;
  cursor: pointer;
}

.connect{
  font-size: 20vh;
  font-weight: 5vh;
  color: #581361;
  position: absolute;
  top: -10vh;
  z-index: -10;
}

@keyframes continuous-flip {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* Internship */
.internship{
  position: relative;
  width: 100%;
  background-image: url("../public/internship-background.png");
  background-repeat: round;
  background-size: cover;
  overflow: hidden;
  min-height: 60vh;
}

@keyframes flyInOut1 {
  0%, 100% {
    transform: translateX(300%);
  }
  50% {
    transform: translateX(0%);
  }
}

@keyframes flyInOut2 {
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(300%);
  }
}

@keyframes flyInOut3 {
  0%, 100% {
    transform: translateX(20%);
  }
  50% {
    transform: translateX(300%);
  }
}

@keyframes flyInOut4 {
  0%, 100% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(280%);
  }
}

.fly-text {
  position: absolute;
  color: white;
  left: 0;
  z-index: 1;
}

.fly-text:nth-child(1){
  font-size: 5vh;
  animation: flyInOut1 20s ease-in-out infinite;
}
.fly-text:nth-child(2){
  top: 15%;
  font-size: 8vh;
  animation: flyInOut2 15s ease-in-out infinite;
}
.fly-text:nth-child(3){
  top: 45%;
  font-size: 4vh;
  animation: flyInOut3 20s ease-in-out infinite;
}
.fly-text:nth-child(4){
  top: 55%;
  font-size: 6vw;
  animation: flyInOut4 10s ease-in-out infinite;
}
.fly-text:nth-child(5){
  top: 75%;
  font-size: 4vw;
  animation: flyInOut1 10s ease-in-out infinite;
}

.fly-text:nth-child(6){
  top: 95%;
  font-size: 2vw;
  animation: flyInOut2 20s ease-in-out infinite;
}

.internship-area{
  top: 0;
  margin: 5%;
  width: 90%;
  z-index: 5;
}

/* Card */
.intern-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin-top: 5%;
  background-color: #c01ed5;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.cut-rectangle {
  width: 90%;
  margin-top: 2%;
  height: 20vh;
  background-size: cover;
  background-position: center;
  clip-path: polygon(
    0% 0%,       /* Top-left */
    100% 0%,     /* Top-right */
    100% 100%,   /* Bottom-right */
    20% 100%,    /* Indented bottom-left */
    0% 80%       /* Bottom-left */
  );
}

/* Quote */
.quote{
  position: relative;
  width: 100%;
  background-image: url("../public/quote-background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.quote-symbol{
  width: 60%;
  height: 80%;
  margin-left: 20%;
}

.quote-text{
  font-size: 10vh;
  font-weight: bold;
  color: azure;
}

/* Projects */
.project{
  position: relative;
  width: 100%;
  background-image: url("../public/project-background.jpg");
  background-repeat: round;
  background-size: cover;
  overflow: hidden;
  min-height: 60vh;
}

.project-title{
  font-size: 10vh;
  margin-left: 5%;
  color: azure;
}

.project-area{
  top: 0;
  margin-left: 5%;
  margin-bottom: 5%;
  width: 90%;
  z-index: 5;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-window {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 60%;
  max-height: 60%;
  overflow-y: auto;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
}
